import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Home',
		component: () => import("@/views/Home.vue")
	},
	{
		path: '/upgrade',
		name: 'Home',
		component: () => import("@/views/Home.vue")
	},
	{
		path: '/share/dynamic/:id',
		name: 'DynamicSharePage',
		props: true,
		component: () => import("@/views/DynamicSharePage.vue")
	},
	{
		path: '/share/group-battle/:id',
		name: 'GroupBattleSharePage',
		props: true,
		component: () => import("@/views/GroupBattleSharePage.vue")
	},
	{
		path: '/share/group-camp/:id',
		name: 'GroupCampSharePage',
		props: true,
		component: () => import("@/views/GroupCampSharePage.vue")
	},
	{
		path: '/share/user/:id',
		name: 'UserSharePage',
		props: true,
		component: () => import("@/views/UserSharePage.vue")
	},
	{
		path: '/test-auth',
		name: 'TestAuth',
		component: () => import("@/views/TestAuth.vue")
	},
	{
		path: '/beijing-jiankangbao',
		name: 'BeijingJianKangBao',
		component: () => import("@/views/BeijingJianKangBao.vue")
	},
	{
		path: '/*',
		name: 'Home',
		component: () => import("@/views/Home.vue")
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
