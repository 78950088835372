import Vue from 'vue';
import Vuetify, {
	VApp,
	VDialog,
	VCard,
	VCardTitle,
	VCardSubtitle,
	VCardText,
	VCardActions
} from 'vuetify/lib';

Vue.use(Vuetify, {
	components: {
		VApp,
		VDialog,
		VCard,
		VCardTitle,
		VCardSubtitle,
		VCardText,
		VCardActions
	}
});

export default new Vuetify({});
